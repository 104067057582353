<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <BreadcrumbPath
      px="0"
      pb="20px"
      :paths="breadcrumbPath"
    />
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '30px']"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <DownloadMealPlan
        v-if="selectedListClientMealPlanDetail?.status !== 'review'"
        :program-id-local="$route.params?.programId"
        :month="$route.params?.month"
        :client-id-local="$route.params?.clientId"
      />

      <c-flex
        flex-direction="column"
        width="100%"
        gap="16px"
      >
        <c-flex
          width="100%"
          flex-direction="row"
          justify-content="space-between"
          align-items="center"
        >
          <c-flex
            w="100%"
            gap="20px"
            align-items="center"
          >
            <c-image
              :w="['80px']"
              :h="['80px']"
              object-fit="cover"
              border-radius="80px"
              :src="getPhotoUser(client?.photoUrl)"
            />
            <c-box w="100%">
              <c-text
                :font-size="['18px']"
                font-weight="500"
                color="neutral.black"
                margin-bottom="8px"
              >
                {{ client?.firstName ?? '...' }} {{ client?.lastName }}
              </c-text>
              <c-text
                :font-size="['14px']"
                font-weight="400"
                color="primary.400"
              >
                {{ client?.phone ?? '...' }}
              </c-text>
            </c-box>
          </c-flex>
          <ChipStatus2
            v-if="selectedListClientMealPlanDetail?.status" 
            :status="selectedListClientMealPlanDetail?.status"
          />
        </c-flex>
        <c-flex
          width="100%"
          justify-content="space-between"
          align-items="center"
        >
          <c-button
            variant-color="secondary"
            color="primary.400"
            border-radius="30px"
            font-size="18px"
            padding="0px 20px"
            font-weight="600"
            :is-disabled="!client?.id"
            @click="onOpenQuestionnaire"
          >
            Formulir Gizi
          </c-button>

          <c-menu
            v-slot="{ isOpen }"
            :auto-select="false"
          >
            <c-menu-button
              variant="outline"
              variant-color="primary"
              font-weight="500"
              font-size="18px"
              gap="14px"
              padding-inline="20px"
              border-radius="40px"
            >
              Panduan Makan Bulan ke-{{ $route.params?.month || 'x' }}
              <c-box
                width="14px"
                height="14px"
                :transform="isOpen ? 'rotate(180deg)' : 'rotate(0deg)'"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-arrow-down.svg')"
                  height="100%"
                  width="100%"
                  fill="#008C81"
                />
              </c-box>
            </c-menu-button>
            <c-menu-list
              padding="0px"
            >
              <c-menu-item
                v-for="(opt) in optionListClientMealPlan"
                :key="opt.month"
                padding="8px 16px"
                font-weight="500"
                font-size="18px"
                :background-color="opt.month === month ? 'primary.50' : 'neutral.white'"
                :is-disabled="opt.isDisabled"
                @click="onChangeMonth(opt)"
              >
                Panduan Makan Bulan ke-{{ opt.month }}
              </c-menu-item>
            </c-menu-list>
          </c-menu>

          <c-menu 
            v-slot="{ isOpen }"
            :auto-select="false"
          >
            <c-menu-button
              :is-active="isOpen"
              variant-color="primary" 
              variant="outline"
              display="flex"
              justify-content="space-between"
              font-size="18px"
              border-radius="40px"
              padding-inline="20px"
              gap="14px"
              font-weight="500"
              :is-disabled="selectedListClientMealPlanDetail?.status !== 'review'"
            >
              Aksi
              <c-box 
                :transform="isOpen ? 'rotate(180deg)' : 'rotate(0deg)'"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-arrow-down.svg')"
                  height="14px"
                  width="14px"
                  fill="#008C81"
                />
              </c-box>
            </c-menu-button>
            <c-menu-list 
              padding="0px"
              placement="bottom-end"
            >
              <c-menu-item 
                v-chakra="{
                  ':hover': {
                    background: 'primary.50',
                  }
                }"
                color="primary.400"
                font-size="18px"
                font-weight="500"
                padding-inline="20px"
                padding-block="10px"
                border-bottom-width="1px"
                border-bottom-color="neutral.superLightGray"
                @click="isOpenConfirmSendToNutritionist = true"
              >
                Revisi
              </c-menu-item>
              <c-menu-item
                v-chakra="{
                  ':hover': {
                    background: 'primary.50',
                  }
                }"
                color="primary.400"
                font-size="18px"
                font-weight="500"
                padding-inline="20px"
                padding-block="10px"
                @click="isOpenPopupConfirmSendToClient = true"
              >
                Kirim ke Klien
              </c-menu-item>
            </c-menu-list>
          </c-menu>
        </c-flex>
      </c-flex>

      <router-view
        :is-edited="isEdited"
        :is-program-consultation-basic="isProgramConsultationBasic"
      />

      <!-- MODAL -->
      <ModalContentQuestionnaire
        :is-open="isOpenQuestionnaire"
        @close="onCloseQuestionnaire"
      />

      <!-- MODAL -->
      <ModalSimpleInfo
        :is-open="isOpenPopupConfirmSendToClient"
        :image="require('@/assets/images/image-question.svg')"
        title="Apakah kamu yakin mengirim panduan makan ke klien?"
      >
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding="0 24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              :is-loading="isLoadingSendToClient"
              @click="isOpenPopupConfirmSendToClient = false"
            >
              Tidak
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingSendToClient"
              @click="handleSendToClient"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </ModalSimpleInfo>

      <!-- MODAL -->
      <ModalSimpleInfo
        :is-open="isOpenSuccessSendToClient"
        :image="require('@/assets/images/success-image.svg')"
        title="Panduan makan berhasil dikirim ke klien"
      >
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding="0 24px"
            margin-bottom="24px"
          >
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="$router.go(0)"
            >
              Oke
            </BaseButton>
          </c-flex>
        </template>
      </ModalSimpleInfo>

      <!-- MODAL -->
      <ModalSimpleInfo
        :is-open="isOpenSuccessSendToNutritionist"
        :image="require('@/assets/images/success-image.svg')"
        title="Catatan revisi berhasil dikirim ke ahli gizi"
      >
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding="0 24px"
            margin-bottom="24px"
          >
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="$router.go(0)"
            >
              Oke
            </BaseButton>
          </c-flex>
        </template>
      </ModalSimpleInfo>

      <!-- MODAL -->
      <BaseModal
        :is-open="isOpenConfirmSendToNutritionist"
        :close-on-overlay-click="true"
        size="xl"
        @close="!isLoadingSendToNutritionist ? isOpenConfirmSendToNutritionist = false : ''"
      >
        <template #header>
          <c-box
            background-color="primary.50"
            font-size="20px"
            font-weight="bold"
            padding="8px 24px"
          >
            Catatan revisi
          </c-box>
        </template>
        <template #body>
          <c-box padding="8px 24px">
            <BaseEditor
              id="limited"
              v-model="note"
              :with-margin="false"
              image-provider-url="/v1/admin/meal-plans/upload"
            />
            <!--              @blur="$v.form.limited.$touch"-->
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="50%"
            padding="0 24px"
            margin="auto"
            margin-bottom="16px"
            margin-top="8px"
          >
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              size="small"
              :disabled="!note || isLoadingSendToNutritionist"
              @click="handleSendToNutritionist"
            >
              Kirim Revisi
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>
  </c-flex>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import generalMixin from '@/utils/general-mixins'
import ModalContentQuestionnaire from '@/components/modals/modal-content-questionnaire.vue'
import {
  reqAdmin_mealPlans_action,
  reqAdmin_mealPlans_clientDetail,
  reqAdmin_mealPlans_getDetail,
  reqAdmin_mealPlans_getOptionList,
} from '@/requests/dietela-api/admin/meal-plan'
import { formatDateV2 } from '@/utils/format-date'
import {
  CBox,
  CButton,
  CFlex,
  CImage,
  CMenu,
  CMenuButton,
  CMenuItem,
  CMenuList,
  CText,
} from '@chakra-ui/vue'
import ChipStatus2 from '@/components/elements/chip-status-2.vue'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseEditor from '@/components/elements/base-editor-2.vue'
import DownloadMealPlan from './_widgets/download-meal-plan.vue'

export default {
  name: 'AdminManagementClientMealPlan',
  components: {
    DownloadMealPlan,
    BaseEditor,
    BaseModal,
    BaseButton, ModalSimpleInfo,
    CBox,
    CText,
    CFlex,
    CImage,
    CButton,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    BreadcrumbPath,
    ModalContentQuestionnaire,
    ChipStatus2,
  },
  mixins: [generalMixin],
  data() {
    return {
      isOpenPopupConfirmSendToClient: false,
      isLoadingSendToClient: false,
      isOpenSuccessSendToClient: false,
      note: '',
      mealPlanId: '',
      isOpenConfirmSendToNutritionist: false,
      isLoadingSendToNutritionist: false,
      isOpenSuccessSendToNutritionist: false,
      isOpenQuestionnaire: false,
      client: {},
      readMore: false,
      totalMonth: 0,
      listClientMealPlan: {},
      selectedListClientMealPlanDetail: {},
      isEllipsis: false,
      intervalCheckIsEllipsis: null,
      program: null,
      isEdited: false,
      isProgramConsultationBasic: false,
    }
  },
  computed: {
    breadcrumbPath() {
      return [
        { 
          label: 'Manajemen Klien', 
          href: { 
            name: 'admin.clients',
          },
        },
        { 
          label: 'Detail Klien', 
          href: {
            name: 'admin.clients-detail', 
            query: this.$route.query,
          },
        },
        { 
          label: 'Detail Program', 
          href: {
            name: 'admin.clients-programs',
            query: this.$route.query,
          },
        },
        { 
          label: 'Panduan Makan', 
          isCurrent: true,
        },
      ]
    },
    month() {
      return parseInt(this.$route.params.month || '0')
    },
    optionListClientMealPlan() {
      if (this.totalMonth < 1) {
        return [
          {
            month: 1,
            isDisabled: false,
          },
        ]
      }
      return Array
        .from({ length: this.totalMonth }, (_, index) => index + 1)
        .map((idx) => {
          const getPrevMealPlan = this.listClientMealPlan.find((it) => it?.month == (idx - 1))
          const isDisabled = idx !== 1 ? (getPrevMealPlan && getPrevMealPlan?.status !== 'draft' ? false : true) : false
          return {
            month: idx,
            isDisabled: isDisabled,
          }
        })
    },
    watchRouteParamsMonthAndListClientMealPlan() {
      return {
        month: this.$route.params.month,
        listClientMealPlan: this.listClientMealPlan,
      }
    },
  },
  watch: {
    watchRouteParamsMonthAndListClientMealPlan: {
      async handler(v) {
        if (v.month && v.listClientMealPlan.length > 0) {
          this.updateSelectedListClientMealPlan()
          const findData = v.listClientMealPlan?.find((v2) => parseInt(v2?.month) === parseInt(v.month || '0'))
          if (!findData) {
            this.selectedListClientMealPlanDetail = {}
            return
          }
          const res3 = await reqAdmin_mealPlans_getDetail(this.$store.getters.axios, {
            id: findData?.id,
          })
          this.selectedListClientMealPlanDetail = res3?.data?.data || {}
          this.mealPlanId = findData?.id
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.init()
    this.initMealPlanStatus()

    this.intervalCheckIsEllipsis = setInterval(() => {
      this.isEllipsis = this.$refs.ooa12?.$el?.scrollHeight > 30
    }, 100)
  },
  destroyed() {
    clearInterval(this.intervalCheckIsEllipsis)
  },
  methods: {
    async init() {
      try {
        const res = await reqAdmin_mealPlans_clientDetail(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
        })
        this.client = res.data?.data
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.data?.message ? err?.data?.message : (err?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      }
    },
    async handleSendToClient() {
      try {
        this.isLoadingSendToClient = true
        if (!this.mealPlanId) {
          this.$toast({
            title: 'Failed',
            description: 'Panduan makan tidak ditemukan',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          return
        }
        await reqAdmin_mealPlans_action(this.$store.getters.axios, {
          id: this.mealPlanId,
          action: 'sent',
          note: 'Send to client',
        })
        this.isOpenPopupConfirmSendToClient = false
        this.isOpenSuccessSendToClient = true
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.data?.message ? err?.data?.message : (err?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isLoadingSendToClient = false
      }
    },
    async handleSendToNutritionist() {
      try {
        this.isLoadingSendToNutritionist = true
        if (!this.note || !this.mealPlanId) {
          this.$toast({
            title: 'Failed',
            description: !this.note ? 'Catatan revisi tidak boleh kosong' : 'Panduan makan tidak ditemukan',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          return
        }
        await reqAdmin_mealPlans_action(this.$store.getters.axios, {
          id: this.mealPlanId,
          action: 'revisi',
          note: this.note,
        })
        this.isOpenConfirmSendToNutritionist = false
        this.isOpenSuccessSendToNutritionist = true
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.data?.message ? err?.data?.message : (err?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isLoadingSendToNutritionist = false
      }
    },
    async initMealPlanStatus() {
      const [res] = await Promise.all(
        [
          reqAdmin_mealPlans_getOptionList(this.$store.getters.axios, {
            programId: this.$route.params?.programId || '',
          }),
        ],
      )
      this.totalMonth = res?.data?.data?.month || 0
      this.listClientMealPlan = res?.data?.data?.mealPlans || []
      this.program = res?.data?.data?.activeProgram || null

      this.updateSelectedListClientMealPlan()
    },
    formatDateV2,
    onOpenQuestionnaire() {
      this.isOpenQuestionnaire = true
    },
    onCloseQuestionnaire() {
      this.isOpenQuestionnaire = false
    },
    dummyFunction() {
      this.$toast({
        title: 'not implement yet!',
        description: 'not implement yet!',
        status: 'warning',
        variant: 'subtle',
        position: 'bottom-right',
      })
    },
    async updateSelectedListClientMealPlan() {
      const findData = this.listClientMealPlan?.find((v2) => parseInt(v2?.month) === this.month)

      this.selectedListClientMealPlanDetail = findData || {}

      const programStatus = this.program?.programStatus
      const mealPlanStatus = findData?.status || ''
      this.isProgramConsultationBasic = (this.program?.isKonsultasi && this.program?.programService?.toLowerCase() === 'basic') ? true : false

      if (programStatus === 'active' && (mealPlanStatus === 'draft' || mealPlanStatus === 'revisi')) {
        this.isEdited = true
      } else {
        this.isEdited = false
      }
    },
    onChangeMonth(option) {
      if (!option?.isDisabled && this.month != option?.month) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            month: option?.month,
          },
        }).catch(() => {})
      }
    },
  },
}
</script>
